import bookingES from './booking/es'
import playerES from './players/es'
const es = {
  common: {
    search: 'Buscar',
    send: 'Enviar',
    title: 'Título',
    filter: 'Filtrar',
    all: 'Todos',
    add: 'Añadir', 
    addItem: 'Añadir elemento',
    newItem: 'Nuevo item',
    actions: 'Acciones',
    preview: 'Preview',
    ok: "Aceptar",
    save: 'Guardar',
    cancel: 'Cancelar',
    delete: 'Borrar',
    deleteSelected: 'Borrar seleccionados',
    move: 'Mover',
    clear: 'Limpiar',
    close: 'Cerrar',
    skip: 'Saltar',
    create: 'Crear',
    edit: 'Editar',
    clone: 'Copiar',
    color: 'Color',
    hide: 'Ocultar',
    show: 'Mostrar',
    download: 'Descargar',
    showAll: 'Mostrar histórico',
    settings: 'Config./ajustes',
    refresh: 'Refrescar',
    of: 'de',
    or: 'o',
    noEndDate: 'Sin fecha fin',
    addToCalendar: 'Añadir al calendario',
    showMore: 'Mostrar más',
    workspace: 'Area de trabajo',
    selectImage: 'Seleccione una imagen',
    itemsPerPage: 'Items por página',
    startDate: 'Fecha inicio',
    endDate: 'Fecha fin', 
    next: 'Siguiente',
    back: 'Anterior',
    more: 'más',
    m0: 'Enero',
    m1: 'Febrero',
    m2: 'Marzo',
    m3: 'Abril',
    m4: 'Mayo',
    m5: 'Junio',
    m6: 'Julio',
    m7: 'Agosto',
    m8: 'Septiembre',
    m9: 'Octubre',
    m10: 'Noviembre',
    m11: 'Diciembre',
    s0: 'Lunes',
    s1: 'Martes',
    s2: 'Miércoles',
    s3: 'Jueves',
    s4: 'Viernes',
    s5: 'Sábado',
    s6: 'Domingo',
    today: 'Hoy',
    tomorrow: 'Mañana',

    infoEditedTranslate: 'Pendiente de traducir',

    alternation: 'Alternancia',
    confirm: 'Confirmar',

    dayOfWeek: {
      0: 'Domingo',
      1: 'Lunes',
      2: 'Martes',
      3: 'Miércoles',
      4: 'Jueves',
      5: 'Viernes',
      6: 'Sábado'
    },
    availability: {
      info: {
        activeDay: 'Activo el día ? ',
        activeFrom: 'Activo desde el ? ',
        noEndDate: 'sin fecha fin ',
        untilThe: 'hasta el ? ',
        atAllHours: '. A todas horas',
        fromTo: '. Desde ? hasta ?',
        theDays: '. Los días ?',
        the: '. Los ?',
        alt: 'Alternancia ?',
        noTemp: 'No tiene programación',
        allDaysAllTimes: 'Todos los días a todas horas',
      },
      totalDuration: 'Total duración del bucle',
    },

    messages: {
      confirmDelete: '¿Está seguro de que desea eliminarlo?', // Are you sure you want to delete this item?
      noData: 'No hay datos a mostrar',
      pleaseStandBy: 'Por favor espere'
    }
  },
  share: {
    downloadSVG: 'descargar en SVG',
  },
  login: {
    username: 'Usuario',
    password: 'Contraseña',
    login: 'Acceder',
    logout: 'Desconectar',
    changePassword: 'Cambiar contraseña',
    messages: {
      userNotFound: 'Usuario o contraseña no encontrado'
    }
  },
  changePassword: {
    oldPassword: 'Contraseña anterior',
    password: 'Nueva contraseña',
    repeatPassword: 'Repetir contraseña',
    messages: {
      oldPasswordRequired: 'La contraseña anterior es requerida',
      passwordRequired: 'La contraseña es requerida',
      sameAsPassword: 'Las contraseñas deben coincidir',
      UserNotFound: 'El usuario o la contraseña no coinciden',
      success: 'Contraseña actualizada correctamente'
    }
  },
  sidebar: {
    dashboard: 'Dashboard',
    clients: 'Clients',
    users: 'Users',
    dviewcontent: 'Dviewcontent',
    dtouch: 'Dtouch',
    personalization: 'Personalización',
    setting: 'Configuración',
    survey: 'Encuestas',
    dev: '< DEV >',
    dfiles: 'Dfiles',
    folders: 'Carpetas',
    queue: 'Turnos',
    logout: 'Salir',
    places: 'Lugares',
    activities: 'Actividades',
    reservations: 'Reservas',
    calendar: 'Calendario',
    relocateReservations: 'Reubicar',
    menus: 'Menus',
    surveyForm: 'Formulario encuesta',
    surveyResult: 'Resultado encuestas',
    menuCheck: 'Pendiente de revisión',
    dishOrders: 'Pedidos',
    welcomeForm: 'Formulario de entrada',
    attendance: 'Registro E/S',
    attendanceReservations: 'Reservas E/S',
    resources: 'Recursos',
    stats: 'Accesos',
    issuesResult: 'Incidencias',
    issuesManager: 'Incidencias administrador',
    requestResult: 'Solicitudes',
    requestManager: 'Solicitudes administrador',
    messages: 'Mensajes',
    interactiveMap: 'Mapas interactivos',
  },
  sysUser: {
    title: 'Usuarios',
    name: 'Nombre',
    password: 'Contraseña',
    email: 'Email',
    environments: 'Entornos',
  },
  workspace: {
    title: 'Gestores',
    name: 'Nombre',
    users: 'Usuarios',
    contentTypes: 'Tipos de contenido',
    renameFolders: 'Renombre carpetas',
    timezone: 'Zona horaria',
    external: 'Externa',
    areas: 'Areas',
    area: 'Area',
    number: 'Número',
    min: 'Min',
    max: 'Max',
    languages: 'Idiomas',
    scheduleType: 'Tipo de programación',
    contentType: {
      type: 'Tipo',
      width: 'Ancho',
      height: 'Alto',
      allowedTypes: 'Tipos permitidos',
      bySequence: 'Por secuencia (5min | 10min | 20min)',
      XMLByMeetingRoom: 'XML separado por sala',
    },
    sidebarPermissions: 'Permisos sidebar :',
    hideItemByType: 'Ocultar items por tipo (menú/contenido)',
    cloneMenu: 'Copiar menú',
    cloneContent: 'Copiar contenido',
    hideDishOrderPoint: 'Ocultar conf. punto de pedidos',
    messages: {
      noEnv: 'El usuario no tiene configurado ninguna area',
      noWorkspace: 'El usuario no tiene configurado ningún workspace para esta area',
      noArea: 'El usuario no tiene configurado ninguna permiso para este workspace',
    },
  },
  internalMaps: {
    point: {
      point: 'Punto de interes',
      title: 'Título',
      description: 'Descripción',
      pos: 'Pos',
      scale: 'Escala',
    },
  },
  customers: {
    name: 'Nombre',
    email: 'Email',
    password: 'Contraseña',
    addCustomer: 'Añadir usuario',
    editCustomer: 'Editar usuario',
    notification: 'Notificar cambios por email',
  },
  folders: {
    editFolder: 'Editar carpeta',
    addFolder: 'Añadir carpeta',
    createFolder: 'Crear carpeta',
    editFile: 'Editar archivo',
    addFile: 'Añadir archivo',
    title: 'Carpetas',
    name: 'Nombre',
    dropFile: 'Arrastre su archivo aquí.',
    folder: 'Carpeta',
    file: 'Archivo',
    showPDF: 'Mostrar pdf',
    visibility: 'Visibilidad',
    privateByUser: 'Para usuarios logueados seleccionados',
    privateAllUser: 'Para todos los usuarios logueados',
    public: 'Público',
  },
  content: {
    alias: 'Alias',
    name: 'Nombre',
    message: 'Mensaje',
    media: 'Media',
    time: 'Tiempo',
    type: 'Tipo',
    sequence: 'Secuencia',
    createQR: 'Crear QR',
    url: 'URL',
    schedule: 'Programación',
    messages: {
      noImage: 'Debe seleccionar una imagen',
      noVideo: 'Debe seleccionar un vídeo',
      generateMediaFeed: 'MediaFeed generado satisfactoriamente',
      selectContent: 'Seleccione un menú o contentido para poder editarlo'
    }
  },
  selectImage: {
    title: 'Selecciones una imagen',
  },
  availability: {
    title: 'Programación',
    isTemp: 'Temporal',
    periodicity: 'Periodicidad',
    allDays: 'Todos los días',
    oneDay: 'Un día',
    recurrence: 'Recurrente',
    isAllTimes: 'Todo el día',
    date: 'Fecha del evento',
    dateStart: 'Fecha de inicio',
    dateEnd: 'Fecha de fin',
    timeStart: 'Hora de inicio',
    timeEnd: 'Hora de fin',
    noEndDay: 'Sin fecha fin',
    byWeek: 'Por semana',
    byMonth: 'Por mes',
    alternation: 'Alternancia'
  },
  dtouch: {
    domain: 'Dominio',
    editMenu: 'Editar menú',
    addMenu: 'Añadir menú',
    addContent: 'Añadir contenido',
    addSubmenu: 'Añadir submenú',
    menu: 'Menú',
    menus: 'Menús',
    content: 'Contenido',
    customize: 'Personalización',
    height: 'Altura',
    email: 'Email',
    room: 'Habitación',
    access: 'Acceder',
    reservations: 'Reservas',
    pax: 'Asistentes',
    showBookings: 'Mostrar reservas',
    editBooking: 'Modificar reserva',
    cancelBooking: 'Cancelar reserva',
    outdatedBooking: 'Finalizado',
    timezone: 'Timezone',
    url: 'URL',
    menuExt: 'URL carta externa',
    simulation: {
      languages: 'Idioma simulación',
      hideSimulation: 'Ocultar simulación',
      showHideContent: 'Mostrar contenido oculto',
      showFullSimulation: 'Mostrar simulación completa',
      showFastEdit: 'Ed. rápida',
      allowSort: 'Permitir ordenar',
      hideSettingForm: 'Ocultar formularion',
    },
    workspaceSetting: {
      title: 'Config. gestor',
    },
    insertBetween: {
      title: 'Insertar antes de ....',
    },
    movePanel: {
      title: 'Mover a ...',
      moveToRoot: 'Mover a raiz',
      menus: 'Menús',
      workspaces: 'Webapps'
    },
    workspace: {
      contentTypes: 'Tipos de contenido',
      norayURL: 'URL',
      norayHotelID: 'ID Hotel',
      charURL: 'URL',
      dfileWorkspace: 'ID Dfile',
      screensaver: 'Protector de pantalla',
      authentication: {
        title: 'Autenticación',
        type: 'Tipo',
      },
      design: {
        title: 'Diseño general',
        workspaceColor: 'Color del gestor',
        btnBGColor: 'BTN.Content. fondo',
        btnTextColor: 'BTN.Content. texto',
        foodDrinkBGColor: 'Comida/bebida/producto fondo',
        foodDrinkTextColor: 'Comida/bebida/producto texto',
        expandFirstElement: 'Expandir el primer menú automáticamente',
        expandFirstElementWithoutMainMenu: 'Expan. sin desp. el menú principal',
        designByCards: 'Diseño de tarjetas',
        designByCardsProminent: 'Diseño de tarjetas prominente',
        designByCardsBarNoImage: 'Cabecera sin fondo (solo color)',
        cacheFormData: 'Cachear datos del formulario',
      },
      messages: {
        title: 'Chat',
        active: 'Activo',
        authRequired: 'Require autenticarse',
        identForm: 'Campos formulario de identificación',
      },
      hotelequia: {
        title: 'Hotelequia',
      },
      iberostar: {
        title: 'Iberostar',
        hotel: 'Hotel',
      },
      activityDviewcontent: {
        title: 'Actividades (dviewcontent)',
        id: 'ID',
      },
      dviewcontentMessages: {
        title: 'Mensajes y avisos (dviewcontent)',
        id: 'ID',
        titleColor: 'Título',
        descriptionColor: 'Descripción',
      },
      showDviewcontent: {
        title: 'Cartelera (dviewcontent)',
        id: 'ID',
      },
      parrillaDviewcontent: {
        title: 'Parrilla (dviewcontent)',
        id: 'ID',
        showDFeel: 'Mostrar mosca DFeel',
      },
      customURLBack: {
        title: 'URL volver personalizado',
        url: 'URL',
      },
      acttiv: {
        title: 'Acttiv',
        hotel: 'Hotel',
        image: 'Imágenes',
        isBook: 'Reservar',
        isTestUrl: 'Pruebas',
      },
      contentmanagerBook: {
        title: 'Reservas (ContentManager)',
        bookingWorkspace: 'ID Book',
      },
      meteo: {
        title: 'Meteo',
        zone: 'Zona',
      },
      dfile: {
        title: 'Dfiles',
        id: 'ID'
      },
      totem: {
        title: 'Totem',
        domain: 'Dominio',
        type: 'Tipo',
        meteo: 'Meteo',
        Activities: 'Actividades',
        workspace: 'Workspace',
        category: 'Categoría',
        interactiveMap: 'Mapa interactivo',
      },
      foodDrink: {
        title: 'Comida/Bebida',
        showDishesImage: 'Mostrar imagen',
        showDishesAllergens: 'Mostrar alérgenos',
        foodIconRight: 'Icono a la derecha',
        foodDrinkColor: 'Color',
        allergenIconType: 'Tipo de icono alérgeno',
        makeExternalPDF: 'Generar CARTAS externas',
      },
      dial: {
        title: 'Dial',
        isCustomDial: 'Es custom dial',
        floatingDialCustom: 'Custom dial',

        whatsApp: 'WhatsApp',
        whatsAppAuth: 'Wh.Auth.',
        whatsAppPhone: 'W.Phone',

        floatingDial: 'Dial',
      },
      menuExtFood: {
        title: 'Comida (menu-ext)',
        id: 'ID',
      },
      welcomeForm:  {
        title: 'Formulario de entrada',
      },
      weeklyMenu: {
        title: 'Menú semanal',
        id: 'ID',
        contentID: 'ID del contenido',
      },
    },
    interactiveMaps: {
      title: 'Mapas interactivos',
      categories: {
        name: 'Nombre',
        icon: 'Icono',
      },
      point: {
        point: 'Punto de interes',
        title: 'Título',
        description: 'Descripción',
        pos: 'Pos',
        scale: 'Escala',
        category: 'Categoría',
      },
    },
    food: {
      name: 'Nombre',
      ingredients: 'Ingredientes',
      price: 'Precio',
      vegan: 'Vegano',
      vegetarian: 'Vegetariano', 
      new: 'Nuevo', 
      recommended: 'Recomendado',
      note: 'Nota',
      allergens: 'Alérgenos',
      image: 'Imagen',
      containsAllergens: 'Contiene alérgeno',
    },
    menuExtFood: {
      externalID: 'ID externo',
      isAll: 'Todos',
    },
    activity: {
      all: 'Todos',
      messages: {
        noActivities: 'No hay actividades para el día seleccionado',
        disclaimer: 'Programa de animación sujeto a posibles cambios',
      },
    },
    acttiv: {
      disclaimer: 'Programa de animación sujeto a posibles cambios',
    },
    linkDomain: {
      name: 'Etiqueta',
      domain: 'Subdominio',
    },
    contact: {
      addItem: 'Añadir item',
      hideDivider: 'Ocultar separador',
      url: 'URL',
      description: 'Descripción',
      lat: 'Latitud',
      lon: 'Longitud',
    },
    iconList: {
      title: 'Título',
      description: 'Descripción',
    },
    linkExternal: {
      name: 'Nombre',
      url: 'URL',
      newTab: 'Abrir en nueva pestaña',
    },
    pdfTranslate: {
      openNewTab: 'Abrir en nueva pestaña',
      btnText: 'Texto botón',
      download: 'Descargar',
    },
    activities: {
      types: 'Filtrar por tipo',
      primaryColor: 'Primario',
      secondaryColor: 'Secundario',
      titleColor: 'Título',
      descriptionColor: 'Descripción',
    },
    site: {
      title: 'Título',
      subtitle: 'Subtítulo',
      address: 'Dirección',
      lat: 'Latitud',
      lon: 'Longitud',
      phone: 'Teléfono',
      web: 'Web',
      email: 'Email',
      moreInfo: 'Más info',
      showMoreInfo: 'Mostrar más información',
      hideMoreInfo: 'Ocultar información',
      siteLink: 'Link',
      siteLinkName: 'Nombre',
      siteLinkURL: 'URL',
      siteLinkDialog: 'Pop-up',
    },
    productService: {
      name: 'Nombre',
      description: 'Descripción',
      price: 'Precio',
      promotion: 'Promoción',
      note: 'Nota',
      image: 'Imagen',
    },
    brochure: {
      title: 'Título',
      subtitle: 'Subtítulo',
      image: 'Imagen',
      description: 'Descripción',
      note: 'Nota',
      facts: 'Datos de interés',
      hours: 'Horarios',
      price: 'Precio',
      services: 'Servicios',
      contact: 'Contacto',
      phone: 'Teléfono',
      fax: 'Fax',
      email: 'Email',
      web: 'Web',
      location: 'Ubicación',
      zone: 'Zona',
      municipaly: 'Municipio',
      address: 'Dirección',
      postalCode: 'Código postal',
      lat: 'Latitud',
      lon: 'Logitud',
      pdf: 'PDF',
      openNewTab: 'Abrir en nueva pestaña',
      download: 'Descargar',
      info: 'Información',
    },
    survey: {
      rangeDates: 'Rango de fechas',
      dates: 'Fechas',
      ident: 'Identificador',
      selectType: 'Seleccione el tipo',
      question: 'Pregunta',
      answer: 'Respuesta',
      multiSelection: 'Multi-selection',
      singleSelection: 'Single selection',
      addAnswer: 'Añadir respuesta',
      rating: 'Valorar',
      number: 'Número',
      messages: {
        sendDataSuccess: 'Encuesta enviada correctamente',
      },
    },
    dishOrders: {
      title: 'Pedidos',
      allowOrders: 'Permitir pedidos',
      order: 'Pedir',
      status: 'Estado',
      cd: 'Fecha creación',
      closed: 'Cerrado',
      closedOpen: 'Cerrado/Abierto',
      open: 'Abierto',
      close: 'Cerrar',
      numItems: 'N. items',
      name: 'Nombre',
      price: 'Precio',
      total: 'Total',
      cancelOrder: 'Cancelar',
      confirmOrder: 'Confirmar',
      image: 'Imagen',
      clearOrder: 'Vaciar',
      all: 'Todos',
      note: 'Nota',
      finalize: 'Finalizar',
      noData: 'El carrito está vacío',
      labelExtra: 'Texto a mostrar',
      onlyOne: 'Permitir seleccionar solo uno',
      FixCart: 'Fijar carrito',
      percentage: 'Porcentaje',
      allInclusive: 'Todo incluido',
      includeExtras: 'Incluir extras',
      terms: 'Términos y condiciones',
      notificationMails: 'Emails de notificación',
      extras: {
        title: 'Extras',
        name: 'Nombre',
        price: 'Precio',
      },
      formConfirm: {
        title: 'Último paso',
        workspaceLabel: 'Campos form. confirm.',
        fullName: 'Nombre completo',
        room: 'Habitación',
        name: 'Nombre',
        lastName: 'Apellido',
        required: 'Requerido',
        acceptTerms: {
          title: 'Aceptar termimos y condiciones.',
          accept: 'Aceptar ',
          terms: 'terminos y condiciones.'
        },
      },
      points: {
        title: 'Puntos de pedidos',
        point: 'Punto de pedido',
        name: 'Nombre',
        order: 'Pedido',
      },
      statusValues: {
        INI: 'Inicial',
        confirmed: 'Confirmado',
        finished: 'Finalizado',
      },
      messages: {
        orderAdded: 'Pedido añadido',
        rememberConfirmOrder: 'Recuerda confirmar el pedido',

        orderConfirmed: 'Pedido confirmado',
      },
    },
    welcomeForm: {
      title: 'Registro entrada',
      name: 'Nombre',
      phone: 'Teléfono',
      email: 'Email',
      refresh: 'Refrescar',
    },
    issues: {
      title: 'Incidencias',
    },
    formCio: {
      title: 'Form CIO',
      label: 'Label',
      name: 'Nombre',
      notificationMails: 'Emails de notificación',
      key: 'Key',
      id: 'ID',
      type: 'Tipo',
      required: 'Requerido',
      value: 'Valor',
      messageConfirm: 'Mensaje de confirmación',
    },
    request: {
      name: 'Nombre',
      customer: 'Cliente',
      description: 'Descripción',
      request: 'Pedir',
      report: 'Reportar',
      title: 'Solicitudes',
      item: 'Item',
      fullName: 'Nombre completo',
      email: 'Email',
      room: 'Habitación',
      comment: 'Comentarios',
      status: 'Estado',
      all: 'Todo',
      last24h: 'Last 24h.',
      messages: {
        ok: 'Solicitud registrada correctamente',
        okUpdate: 'Solicitud actualizada correctamente',
        fieldsRequired: 'Faltan campos por informar (*)',
      },
    },
  },
  meteo: {
    title: 'Meteo',
    name: 'Nombre',
    zone: 'Lugar',
    URL: 'URL',
    turismo: 'Turismo',
    lat: 'Latitud',
    lon: 'Longitud',
  },
  turismo: {
    title: 'Turismo',
    user: 'Usuario',
    permissions: 'Permisos',
    workspace: 'Oficina',
    menuCheck: 'Elemento a revisar',
    image: 'Imagen',
    longitude: 'Longitud',
    latitude: 'Latitud',
    email: 'Email',
    phone: 'Teléfono',
    web: 'Web',
    service: 'Servicio',
    services: 'Servicios',
    address: 'Dirección',
    timetable: 'Horario',
    show: 'Ver',
    showAll: 'Mostrar todo',
    convertToOwner: 'Asignar a oficina',
    assigned: 'Asignado',
    assignedTo: 'Asignado a',
    menuContent: {
      title: 'Menús / Contenido',
      description: '',
      addMenu: 'Añadir menú / contenido',
    },
    survey: {
      creationDate: 'Fecha creación',
      visitorCenter: 'Oficina',
      comment: 'Comentario',
      title: 'Encuentas',
      description: '',
      questions: 'Preguntas',
      question: 'Pregunta',
      totalPax: 'Total participantes',
      addQuestion: 'Añadir pregunta',
      results: 'Resultados',
    },
    permission: {
      dashboard: 'Escritorio (sidebar)',
      dashboardInfo: 'permite que el usuario pueda ver el dashborard.',
      menus: 'Menús/contenido (sidebar)',
      menusInfo: 'permite que el usuario pueda acceder al apartado de menús/contenido.',
      setting: 'Configuración (sidebar)',
      settingInfo: 'permite que el usuario pueda acceder a la configuración que define el alias, fondo y mapa de las zonas insular/comarcal/local.',
      surveyForm: 'Encuesta configuración (sidebar)',
      surveyFormInfo: 'permite que el usuario pueda acceder a la configuración de las encuestas (las encuestas son iguales para todas las oficinas).',
      surveyResult: 'Encuestas resultados (sidebar)',
      surveyResultInfo: 'permite que el usuario pueda acceder a los resultados de las encuestas',
      menuCheck: 'Pendiente de revisión (sidebar)',
      menuCheckInfo: 'permite que el usuario pueda acceder a los menús/contenido pendiente de revisión para su revisión.',
      create: 'Crear menús/contenido',
      createInfo: 'permiso que permite crear menús/contenido',
      updateAll: 'Actualizar menús/contenido (independiente a la oficina)',
      updateAllInfo: 'permiso que permite actualizar los menús/contenido (independientemente de la oficina a la que pertenezca).',
      deleteAll: 'Eliminar menú/contenido (independiente a la oficina)',
      deleteAllInfo: 'permiso que permite eliminar los menús/contenido (independientemente de la oficina a la que pertenezca).',
      hideAll: 'Ocultar menú/contenido (independiente a la oficina)',
      hideAllInfo: 'permiso que permite ocultar los menús/contenido (independientemente de la oficina a la que pertenezca).',
      updateOwner: 'Actualizar menús/contenido que pertenezca a la oficina',
      updateOwnerInfo: 'permiso que permite actualizar los menús/contenido (solo los que pertenecen a la oficina).',
      deleteOwner: 'Eliminar menús/contenido que pertenezca a la oficina',
      deleteOwnerInfo: 'permiso que permite  eliminar los menús/contenido (solo los que pertenecen a la oficina).',
      hideOwner: 'Ocultar menú/contenido que pertenezca a la oficina',
      hideOwnerInfo: 'permiso que permite ocultar los menús/contenido (solo los que pertenecen a la oficina).',
      blockAsRequired: 'Bloquear contenido', 
      blockAsRequiredInfo: 'permiso que permite bloquear los menús/contenido para que los usuarios normales no puedan realizar modificaciones sobre el.', 
      convertToOwner: 'Asignar menús/contenido a oficina',
      convertToOwnerInfo: 'permiso que permite asignar menús/contenido a una oficina',
      stats: 'Accesos',
      statsInfo: 'permiso que permite mostrar accesos y estadísticas de uso',
    },
  },
  activity: {
    type: 'Tipo',
    show: 'Mostrar',
    messages: {
      noActivities: 'No hay actividades para el día seleccionado'
    },
    book: 'Reservar'
  },
  contentType: {
    menu: 'Menú',
    position: 'Posición',
    selectMenuType: 'Seleccione el tipo de contenido a añadir',
    demo: 'Demo',
    activities: 'Actividades',
    submenu: 'Submenú',
    simpleText: 'Texto simple',
    space: 'Separador',
    image: 'Imagen',
    iframe: 'Iframe',
    alert: 'Alerta',
    gallery: 'Galería',
    video: 'Vídeo',
    meteo: 'Meteo',
    map: 'Mapa',
    twitter: 'Twitter',
    facebook: 'Facebook',
    pdf: 'PDF',
    timeline: 'Timeline',
    text: 'Texto',
    contact: 'Contacto',
    divider: 'Línea',
    shows: 'Cartelera',
    site: 'Sitio',
    agenda: 'Agenda',
    parrilla: 'Parrilla',
    wifi: 'Wifi',
    property: 'Propiedades',
    commercial: 'Agentes',
    queue: 'Turnos',
    food: 'Comida/Bebida',
    linkDomain: 'Enlace webapp',
    form: 'Formulario',
    productService: 'Producto/Servicio',
    pdfTranslate: 'PDF T.',
    linkExternal: 'Enlace externo',
    channelList: 'Lista canales',
    WAAsContent: 'WebApp como contenido',
    acttiv: 'Acttiv',
    poll: 'Encuestas',
    instagram: 'Instagram',
    iconList: 'Lista de iconos',
    dropdownButton: 'Botón desplegable',
    dfiles: 'dfiles',
    rcovid: 'R-Covid',
    imageTranslate: 'Image T.',
    iberostarFood: 'Iberostar',
    icons: 'Iconos',
    hsconsulting: 'Hsconsulting',
    brochure: 'Folleto',
    infoTen: 'InfoTen',
    cMBookingMyBookings: 'Mostrar reservas (ContentManager)',
    cMBookingActivities: 'Actividades (ContentManager)',
    menuExtFood: 'Menu ext comida',
    cMAttendance: 'Registro entrada/salida',
    cMWeeklyMenu: 'Menú semanal',
    cMRequests: 'Peticiones',
    cMIssues: 'Incidencias',
    formCio: 'Form CIO',
    dviewcontentMessages: 'Mensajes y avisos (Dviewcontent)',
    interactiveMaps: 'Mapas interactivos',
    abamaRequestReservation: 'Abama solicitud reserva',
  },
/*
  booking: {
    name: 'Nombre',
    description: 'Descripción',
    allowReservations: 'Permitir reservas',
    reservationRequired: 'Requiere reserva',
    moreInfo: '+ info',
    cancelReservation: 'Cancelar r.',
    reservation: 'Reservar',
    addCalendar: '+ CAL',
    place: 'Lugar',
    meetingPoint: 'Punto de encuentro',
    lat: 'Latitud',
    lon: 'Longitud',
    acttiv: {
      hotel: 'Hotel',
    },
    activities: {
      title: 'Actividades',
      addActivity: 'Añadir actividad',
      editActivity: 'Editar actividad',
      seats: 'Plazas',
    },
    schedules: {
      title: 'Programaciones',
      addSchedule: 'Añadir programación',
    },
    scheduleForm: {
      title: 'Programa',
      alias: 'Alias',
      startTime: 'Hora inicio',
      interval: 'Intervalo',
      type: 'Tipo',
      byDay: 'Por fechas',
      byWeek: 'Por semanas',
      byMonth: 'Por mes',
      allDay: 'Todo el día',
      duration: 'Duración',
    },
    formFields: {
      owner: 'Comprador',
      pax: 'Asistente',
      title: 'Campos del formulario',
      name: 'Nombre',
      lastName: 'Apellidos',
      email: 'Email',
      fullName: 'Nombre completo',
      phone: 'Teléfono',
      room: 'Habitación',
      locale: 'Idioma',
      age: 'Edad',
      allergens: 'Alérgenos',
      observations: 'Observaciones',
      add: 'Añadir',
      type: 'Tipo',
    },
    calendar: {
      today: 'Hoy',
      day: 'Día',
      week: 'Semana',
      month: 'Mes',
      fourDays: '4 días',
      available: 'Disponibles',
      reserved: 'Reservadas',
      canceled: 'Canceladas',
      goToReservationList: 'Ir al listado de reservas', 
    },
    notifications: {
      title: 'Notificaciones',
      sendOwnerMail: 'Enviar email de reserva al cliente',
    },
    reservations: {
      title: 'Reservas',
      field: 'Campo',
      addReservation: 'Añadir reserva',
      editReservation: 'Editar reserva',
      clientData: 'Datos del cliente',
      paxData: 'Datos de participantes',
      addPax: 'Añadir participante',
      pax: 'Participante',
      owner: 'Cliente',
      time: 'Hora',
      activity: 'Actividad',
      cu: 'Creado por',
      lmu: 'Actualizado por',
      withBooks: 'con reservas',
      withCancelBooks: 'con reservas canceladas',
      required: 'Requerido',
      key: 'Identificador interno',
      label: 'Etiqueta',
      activityColor: {
        typeColor: 'Tipo',
        weekPrimaryColor: 'Semana primario',
        weekSecondaryColor: 'Semana secundario',
        contentColor: 'Color contenido',
        titleColor: 'Color título',
        timeColor: 'Color hora',
      },
      formFieldsTypes: {
        title: 'Tipo',
        text: 'Texto',
      },
      status: {
        title: 'Estado',
        default: 'Reservado',
        canceled: 'Cancelado',
      },
      messages: {
        fieldRequired: 'Requerido',
        fieldNotValid: 'No válido',
        noData: 'No existen reservas para la fecha seleccionada',
        noEventsForSelectedDate: 'No existen eventos para la fecha seleccionada',
        statusCanceledNoEditable: 'Las reservas canceladas no se pueden editar',
      },
    },
  },
*/
  booking: bookingES,
  players: playerES,
}
  
export default es
  

<template style="text-align: center;">
  <div v-if="!isMobile" style="text-align: center;width:100%;">
    <v-tooltip
      v-for="item in environments.filter(x => environmentsFilter.length === 0 || environmentsFilter.indexOf(x.name) >= 0)"
      :key="item.path"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="environment !== item.name ? '#e2e2e1' : item.name === 'sys' ? 'red' : 'primary'"
          v-bind="attrs"
          v-on="on"
          :dark="environment !== item.name ? false :  true"
          style="margin-right:5px"
          :elevation="environment === item.name ? 1 : 0"
          @click="handleGoTo(item)"
        >
          <v-icon 
            :style="{ opacity: environment !== item.name ? '40%' : '100%' }"
          >
            {{ item.icon }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ item.name }}</span>
    </v-tooltip>
    <!--v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="environment === 'sys' ? 'red' : 'primary'"
          v-bind="attrs"
          v-on="on"
          dark
          elevation="0"
        >
          <v-icon style="margin-right: 5px;">
            {{ environments.filter(x => x.name === environment).map(item => item.icon).shift() }}
          </v-icon>
            {{ environment }}
          <v-icon style="margin-left: 15px;">
            mdi-menu-down
          </v-icon>
        </v-btn>
      </template>
      <template>
        <v-card>
          <v-card-text style="padding-top: 20px;">
            <v-row
              cols="12"
              xs="12"
              md="4"
            >
              <v-col
                cols="12"
                xs="12"
                md="4"
                v-for="item in environments.filter(x => environmentsFilter.length === 0 || environmentsFilter.indexOf(x.name) >= 0)"
                :key="item.path"
              >
                <v-btn
                  :color="environment !== item.name ? '#e2e2e1' : item.name === 'sys' ? 'red' : 'primary'"
                  :elevation="environment === item.name ? 1 : 0"
                  block
                  :dark="environment !== item.name ? false :  true"
                  @click="handleGoTo(item)"
                  :style="{ opacity: environment === item.name ? 1 : 1 }"
                >
                  <v-icon dark style="margin-right: 5px;">{{ item.icon }}</v-icon> {{ item.name }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-dialog-->
  </div>

  <v-list v-else>
    <v-list-item
      v-for="item in environments.filter(x => environmentsFilter.length === 0 || environmentsFilter.indexOf(x.name) >= 0)"
      :key="item.path"
      @click="handleGoTo(item)"
      :style="{ opacity: environment === item.name ? 1 : 0.3 }"
    >
      <v-list-item-icon>
        <v-icon v-text="item.icon"
          :color="item.name === 'sys' ? 'red' : 'primary'"
        />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title 
          :color="item.name === 'sys' ? 'red' : 'primary'"
        >{{item.name}}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import constants from '@/constants'
import api from '@/services/api'
import utils from '@/services/utils'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      dialog: false,
      environments: [],
    }
  },
  computed: {
    ...mapState('app',['locale', 'environmentsFilter']),
    environment () {
      return this.$route.meta.environment
    },
    isMobile () {
      return utils.isMobile()
    },
  },
  mounted () {
    if (localStorage.getItem('environmentsFilter')) {
      const aux = localStorage.getItem('environmentsFilter').split(',').filter(x => x.length > 0)
      if (aux !== this.$store.state.app.environmentsFilter && this.$store.state.app.environmentsFilter.length === 0) {
        this.$store.state.app.environmentsFilter = aux
      }
    }
    api.getItem ('system', `v1/internal/environments/`, utils.getUser())
      .then(response => {
        this.environments = constants.environments.filter(x => response.Environments.indexOf(x.name) >= 0)
        if (this.environments.length === 0) this.$router.push('/no-env')
        else if (!this.environment) this.handleGoTo(this.environments[0])
      })
    this.prepareColor(this.environment)
    if (this.environments !== 'dtouch') this.$store.state.app.dtouchDeeplTranslateActive = false
  },
  methods: {
    prepareColor (v) {
      if (v === 'status') {
        this.$vuetify.theme.themes.light.primary = '#000000'
      } else if (v === 'status2') {
        this.$vuetify.theme.themes.light.primary = '#000000'
      } else {
        this.$vuetify.theme.themes.light.primary = '#374047'
      }
    },
    handleGoTo (v) {
      if (v.name !== 'dtouch') this.$store.state.app.dtouchDeeplTranslateActive = false
      if (v.path !== `/${this.environment}`) { 
        this.prepareColor(v.name)
        this.$store.state.app.environment = v.name
        this.$router.push(v.path)
      }
      this.dialog = false
    }
  }
}
</script>

<template>
  <v-app-bar v-if="!isMobile" app :elevation="1" clipped-left class="main-bar">
    <div>
      <v-img
        :src="'./img/logo_contentmanager.png'"
        height="64px"
        width="auto"
        contain
      />
    </div>
    <v-app-bar-nav-icon @click="onChangeDrawer" />
    <environments />
    <!-- <v-toolbar-title>Application</v-toolbar-title> -->
    <v-spacer />
    <workspace />
    <!--v-icon dark x-large color="primary">mdi-account-circle</v-icon-->
    <user-menu />
  </v-app-bar>
  <v-app-bar v-else app :elevation="1" clipped-left class="main-bar">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon
          v-bind="attrs"
          v-on="on"
          style="margin-left:5px;"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-img
              :src="'./img/logo_contentmanager.png'"
              height="64px"
              width="auto"
              contain
            />
          </v-list-item>
        </v-list>

        <v-divider />
        <environments />
      </v-card>
    </v-menu>
    <workspace />
  </v-app-bar>
</template>

<script>
import utils from '@/services/utils'
  import Environments from '@/components/Environments'
  import UserMenu from '@/components/userMenu/Index'
  import Workspace from './Workspace'
  export default {
    components: { Environments, Workspace, UserMenu, },
    props: {
      onChangeDrawer: {
        type: Function,
        required: true
      },
    },
    computed: {
      isMobile () {
        return utils.isMobile()
      },
    },
  }
</script>

<style>
  .main-bar .v-toolbar__content, .main-bar .v-toolbar__extension {
    padding: 0px !important;
    background-color: #eceeef;
  }
</style>

